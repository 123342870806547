import { extendTheme } from "@chakra-ui/react"

const theme = extendTheme({
  config: {
    initialColorMode: "dark",
    useSystemColorMode: false,
  },
  styles: {
    global: {
      // styles for the `body`
      body: {
        bg: 'gray.900',
        color: 'gray.300',
      },
      // styles for the `a`
      a: {
        textDecoration: 'none !important',
        color: 'gray.300',
        _hover: {
          textDecoration: 'none !important',
          cursor: 'pointer'
        }
      },
      h1: {
        fontSize: "1.75em",
        fontWeight: "bold"
      },
      h2: {
        fontSize: "1.5em",
        fontWeight: "bold"
      },
      '.main-container': {
        opacity: 0,
        transition: 'opacity 0.5s ease-in-out',
      },
      '.main-container.visible': {
        opacity: 1,
      },
      '*:focus': {
        boxShadow: 'none !important'
      },
      '.cursorPointer': {
        cursor: 'pointer'
      },
      code: {
        borderRadius: '10px'
      },
      '.public-DraftStyleDefault-pre': {
        border: '1px solid #30373d',
        fontFamily: "'Inconsolata', 'Menlo', 'Consolas', monospace",
        fontSize: '0.9em',
        padding: "20px",
        borderRadius: "1em",
        margin: "15px 0",
        overflow: "auto",
      },
      '.public-DraftEditorPlaceholder-root .public-DraftEditorPlaceholder-inner': {
        position: "absolute",
        opacity: "0.5",
      },
      '.public-DraftStyleDefault-block.public-DraftStyleDefault-ltr': {
        marginRight: "25px"
      },
      IconButton: {
          cursor:'pointer'
        }
    }
  },
  colors: {
    dark: {
      100: '#1b202c',
      200: '#2d3748'
    },
    light: {
      100: "#fff"
    }
  },
  components: {
    Button: {
      // 1. We can update the base styles
      baseStyle: {
        fontWeight: 'bold',
        cursor: 'pointer',
      },
      // 2. We can add a new button size or extend existing
      // sizes: {
      //   xl: {
      //     h: '56px',
      //     fontSize: 'lg',
      //     px: '32px',
      //   },
      // },
      // 3. We can add a new visual variant
      // variants: {
      //   'with-shadow': {
      //     bg: 'red.400',
      //     boxShadow: '0 0 2px 2px #efdfde',
      //   },
      //   // 4. We can override existing variants
      //   solid: (props) => ({
      //     bg: props.colorMode === 'dark' ? 'red.300' : 'red.500',
      //   }),
      //   // 5. We can add responsive variants
      //   sm: {
      //     bg: 'teal.500',
      //     fontSize: 'md',
      //   },
      // },
      // 6. We can overwrite defaultProps
      defaultProps: {
        size: 'md', // default is md
        variant: 'outline', // default is solid
        // colorScheme: 'white', // default is gray
        // bg: 'red',
        // color: 'yellow'
      },
    },
  }
})

export default theme