import React, { useState, useRef, forwardRef, useImperativeHandle } from 'react';
import {
  Button,
  Flex,
  Input,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  VStack,
  Text,
  useDisclosure
} from '@chakra-ui/react';
import { supabaseClient } from '../utils/client';
import { BsApple, BsGoogle } from "react-icons/bs";

const Signup = forwardRef((_, ref) => {
  const [email, setEmail] = useState('');
  const [buttonText, setButtonText] = useState('Sign up');
  const [headerText, setHeaderText] = useState('');
  const [buttonVariant, setButtonVariant] = useState('solid');
  const emailRef = useRef(null);
  const emailSubmitButtonRef = useRef(null);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useImperativeHandle(ref, () => ({
    openModal: (text, variant, header) => {
      setButtonText(text);
      setButtonVariant(variant);
      setHeaderText(header);
      onOpen();
    }
  }));

  const handleEmailSubmit = async () => {
    emailSubmitButtonRef.current.disabled = true;
    const { error } = await supabaseClient.auth.signInWithOtp({
      email: emailRef.current.value,
    });
    emailSubmitButtonRef.current.disabled = false;

    if (error) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } else {
      toast({
        title: 'Success',
        description: 'Verification email sent!',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      onClose();
    }
  };

  const handleSSO = async (provider) => {
    const { error } = await supabaseClient.auth.signInWithOAuth({
      provider,
      options: {
        redirectTo: window.location.origin,
      },
    });

    if (error) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent pt="30px" px="40px">
        <ModalHeader fontSize="2em" textAlign="center">{buttonText} {headerText}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb="50px">
          <VStack spacing={4} align="stretch">
            <Flex>
              <Input
                width='100%'
                placeholder='Email'
                mr='4'
                type='text'
                ref={emailRef}
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleEmailSubmit();
                  }
                }}
                autoFocus
              />
              <Button onClick={handleEmailSubmit} ref={emailSubmitButtonRef} colorScheme='blue' variant="solid">
                {buttonText}
              </Button>
            </Flex>
            <Text textAlign="center" fontWeight="600" fontSize="xl">or</Text>
            <Button
              width="100%"
              leftIcon={<BsGoogle />}
              onClick={() => handleSSO('google')}
            >
              Continue with Google
            </Button>
            {/* <Button
              width="100%"
              leftIcon={<BsApple />}
              onClick={() => handleSSO('apple')}
            >
              Continue with Apple
            </Button> */}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
});

export default Signup;
