import { ChakraProvider, useMediaQuery } from "@chakra-ui/react";
import React, { useState, useEffect, useCallback, useRef } from "react";
import { supabaseClient } from "../utils/client";
import customTheme from "../lib/theme";
import UserProvider from '../contexts/user';
import '../styles/globals.css';
import FooterAsButton from '../components/FooterAsButton';
import JobsToastNotifier from "../utils/JobsToastNotifier";
import BottomNavBar from "../components/BottomNavBar";
import SimpleSidebar from "../components/Sidebar";
import { useUser } from "../contexts/user";
import { useRouter } from 'next/router';

import posthog from "posthog-js";
import { PostHogProvider } from 'posthog-js/react';

if (typeof window !== 'undefined') {
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://posthog.uprelic.com',
    loaded: (posthog) => {
      if (process.env.NODE_ENV === 'development') posthog.debug();
    },
  });
}

function MyApp({ Component, pageProps }) {
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)');
  const [renderReady, setRenderReady] = useState(false);
  const [fadeIn, setFadeIn] = useState(true);
  const [messages, setMessages] = useState([]);
  const [selectedChatId, setSelectedChatId] = useState(null);
  const { user } = useUser();
  const editorRef = useRef(null);
  const router = useRouter();

  useEffect(() => {
    supabaseClient.auth.onAuthStateChange((event, session) => {
      if (event === 'SIGNED_OUT' || event === 'USER_DELETED') {
        const expires = new Date(0).toUTCString();
        document.cookie = `uprelic-supabase-access-token=; path=/; expires=${expires}; SameSite=Lax; secure`;
        document.cookie = `uprelic-supabase-refresh-token=; path=/; expires=${expires}; SameSite=Lax; secure`;
      } else if (!!session && (event === 'SIGNED_IN' || event === 'TOKEN_REFRESHED' || event === 'INITIAL_SESSION')) {
        const maxAge = 100 * 365 * 24 * 60 * 60; // 100 years, never expires
        document.cookie = `uprelic-supabase-access-token=${session.access_token}; path=/; max-age=${maxAge}; SameSite=Lax; secure`;
        document.cookie = `uprelic-supabase-refresh-token=${session.refresh_token}; path=/; max-age=${maxAge}; SameSite=Lax; secure`;
      }
    });
  }, []);

  useEffect(() => {
    if (isLargerThan768 !== undefined) {
      setRenderReady(true);
    }
  }, [isLargerThan768]);

  useEffect(() => {
    const chatId = router.query.chat;
    if (chatId) {
      setSelectedChatId(chatId);
    }
  }, router.query.chat);

  const fetchMessages = useCallback(async (chatId, userId) => {
    if (!chatId ) return null;
    try {
      if (userId) { //logged in user
        const { data, error } = await supabaseClient
          .from('chat_messages')
          .select('*')
          .eq('chat_id', chatId)
          .eq('supabase_user_id', userId)
          .order('created_at', { ascending: true });

        if (error) throw error;
        return data;
      } else { //guest user
        const { data, error } = await supabaseClient
          .from('chat_messages_guests')
          .select('*')
          .eq('chat_id', chatId)
          .order('created_at', { ascending: true });

        if (error) throw error;
        return data;
      }
    } catch (error) {
      console.error("Error fetching messages:", error.message);
      return [];
    }
  }, []);

  const handleChatReset = () => {
    setMessages([]);
  };

  const resetSelectedChatId = () => {
    setSelectedChatId(null);
    setMessages([]);
    handleChatReset();
  };

  if (!renderReady) {
    return null;
  }

  return (
    <ChakraProvider theme={customTheme}>
      <PostHogProvider client={posthog}>
        <UserProvider>
          <SimpleSidebar
            fetchMessages={fetchMessages}
            setMessages={setMessages}
            currentChatId={selectedChatId}
            handleChatReset={handleChatReset}
            setFadeIn={setFadeIn}
            resetSelectedChatId={resetSelectedChatId}
            editorRef={editorRef}
          >
            <Component
              {...pageProps}
              fetchMessages={fetchMessages}
              setMessages={setMessages}
              handleChatReset={handleChatReset}
              setFadeIn={setFadeIn}
              messages={messages}
              editorRef={editorRef}
              setChatId={setSelectedChatId}
              currentChatId={selectedChatId}
              resetSelectedChatId={resetSelectedChatId}
            />
          </SimpleSidebar>
          {isLargerThan768 ? <FooterAsButton /> : <BottomNavBar resetSelectedChatId={resetSelectedChatId} handleChatReset={handleChatReset} editorRef={editorRef} />}
          <JobsToastNotifier />
        </UserProvider>
      </PostHogProvider>
    </ChakraProvider>
  );
}

export default MyApp;
