import React, { useState, useEffect, useCallback, useRef } from "react";
import {
    IconButton,
    Box,
    CloseButton,
    Flex,
    Icon,
    useColorModeValue,
    Drawer,
    DrawerContent,
    useDisclosure,
    Image,
    Text,
    Button,
    useBreakpointValue,
    VStack
} from '@chakra-ui/react';
import { Reorder } from "framer-motion";
import Link from 'next/link';
import { supabaseClient } from "../utils/client";
import { useUser } from "../contexts/user";
import { useRouter } from 'next/router';
import { RiAddCircleLine } from "react-icons/ri";
import { FiSettings, FiMenu, FiList } from 'react-icons/fi';
import Signup from '../components/Signup';

const LinkItems = [
    { name: 'Create New Chat', icon: RiAddCircleLine },
    { name: 'Chats', icon: FiList, isChats: true, url: '/chats' },
    { name: 'Settings', icon: FiSettings, url: '/settings' },
];

export default function SimpleSidebar({ fetchMessages, setMessages, currentChatId, handleChatReset, setFadeIn, resetSelectedChatId, editorRef, children }) {
    const { user } = useUser();
    const [chats, setChats] = useState([]);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const router = useRouter();
    const signupRef = useRef();

    const fetchChats = useCallback(async () => {
        if (!user.id) {
            console.log("User ID is undefined, cannot fetch chats.");
            return;
        }

        try {
            const { data: chatsData, error: chatsError } = await supabaseClient
                .from('chats')
                .select('id, name, last_opened_at, last_message_at')
                .order('last_opened_at', { ascending: false });

            if (chatsError) throw chatsError;

            const updatedChats = await Promise.all(chatsData.map(async (chat) => {
                if (!chat.name) {
                    const { data: messagesData, error: messagesError } = await supabaseClient
                        .from('chat_messages')
                        .select('content')
                        .eq('chat_id', chat.id)
                        .order('created_at', { ascending: true })
                        .limit(1);

                    if (messagesError) {
                        console.error("Error fetching first message:", messagesError.message);
                        return chat;
                    }

                    if (messagesData.length > 0) {
                        chat.name = messagesData[0].content;
                    }
                }
                return chat;
            }));

            setChats(updatedChats);
        } catch (error) {
            console.error("Error fetching chats:", error.message);
        }
    }, [user.id]);

    useEffect(() => {
        if (currentChatId && router.pathname === '/') {
            selectChat(currentChatId);
        }
        if (user.id) {
            fetchChats();
        }
        const chatsSubscription = supabaseClient
            .channel('chatUpdates')
            .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: 'chats' }, payload => {
                if (payload.new.last_opened_at) {
                    fetchChats();
                }
            })
            .on('postgres_changes', { event: 'INSERT', schema: 'public', table: 'chats' }, payload => {
                fetchChats();
            })
            .on('postgres_changes', { event: 'DELETE', schema: 'public', table: 'chats' }, payload => {
                fetchChats();
            })
            .subscribe();

        return () => {
            chatsSubscription.unsubscribe();
        };
    }, [currentChatId, fetchChats, user.id]);

    const selectChat = async (chatId) => {
        setFadeIn(false);

        setChats(prevChats => {
            const chatIndex = prevChats.findIndex(chat => chat.id === chatId);
            if (chatIndex > -1) {
                const newChats = [...prevChats];
                const [selectedChat] = newChats.splice(chatIndex, 1);
                newChats.unshift(selectedChat);
                return newChats;
            }
            return prevChats;
        });

        requestAnimationFrame(() => {
            requestAnimationFrame(async () => {
                router.push(`/?chat=${chatId}`, undefined, { shallow: true });

                const { error } = await supabaseClient
                    .from('chats')
                    .update({ last_opened_at: new Date().toISOString() })
                    .eq('id', chatId)

                if (error) {
                    console.error("Error updating last opened time:", error.message);
                    return;
                }

                setFadeIn(true);
            });
        });
    };

    const handleCreateNewChat = () => {
        resetSelectedChatId();
        handleChatReset();
        router.push('/');
        if (editorRef && editorRef.current) {
            editorRef.current.focus();
        }
    };

    return (
        <Box minH="100vh" bg={useColorModeValue('gray.100', 'gray.900')}>
            <SidebarContent
                onClose={onClose}
                display={{ base: 'none', md: 'flex' }}
                chats={chats}
                selectChat={selectChat}
                resetSelectedChatId={resetSelectedChatId}
                handleChatReset={handleChatReset}
                router={router}
                signupRef={signupRef}
                loading={user.loading}
                handleCreateNewChat={handleCreateNewChat}
            />
            <Drawer
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                returnFocusOnClose={false}
                onOverlayClick={onClose}
                size={{ base: 'xs', md: 'full' }}
            >
                <DrawerContent>
                    <SidebarContent
                        onClose={onClose}
                        resetSelectedChatId={resetSelectedChatId}
                        handleChatReset={handleChatReset}
                        router={router}
                        signupRef={signupRef}
                        loading={user.loading}
                        handleCreateNewChat={handleCreateNewChat}
                    />
                </DrawerContent>
            </Drawer>
            <MobileNav display={{ base: 'flex', md: 'none' }} onOpen={onOpen} router={router} resetSelectedChatId={resetSelectedChatId} handleChatReset={handleChatReset} handleCreateNewChat={handleCreateNewChat} />
            <Box ml={{ base: 0, md: 60 }}>
                {children}
            </Box>
            <Signup ref={signupRef} />
        </Box>
    );
}

const SidebarContent = ({ onClose, chats, selectChat, router, resetSelectedChatId, handleChatReset, signupRef, url, loading, handleCreateNewChat, ...rest }) => {
    const displayChats = useBreakpointValue({ base: false, md: true });
    const displayMobileLinks = useBreakpointValue({ base: true, md: false });
    const { user } = useUser();

    return (
        <Box
            bg={useColorModeValue('white', 'gray.800')}
            borderRight="1px"
            borderRightColor={useColorModeValue('gray.200', 'gray.700')}
            w={{ base: 'full', md: 60 }}
            pos="fixed"
            h="full"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            {...rest}>
            <Flex flexDirection="column">
                <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
                    <Box
                        onClick={() => {
                            router.push('/');
                            resetSelectedChatId();
                            handleChatReset();
                            handleCreateNewChat();
                        }}
                        className='cursorPointer'>
                        <Image
                            boxSize='120px'
                            height={'auto'}
                            objectFit='contain'
                            src='uprelic-logo-transparent.png'
                            alt='Uprelic'
                        />
                    </Box>
                    <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
                </Flex>
                {displayChats && LinkItems.filter(item => item.name !== 'Settings').map((link) => (
                    <NavItem
                        key={link.name}
                        icon={link.icon}
                        isChats={link.isChats}
                        chats={chats}
                        selectChat={selectChat}
                        resetSelectedChatId={resetSelectedChatId}
                        handleChatReset={handleChatReset}
                        router={router}
                        isActive={router.pathname === link.url}
                        url={link.url}
                        signupRef={signupRef}
                        loading={loading}
                        handleCreateNewChat={handleCreateNewChat}
                        user={user}
                    >
                        {link.name}
                    </NavItem>
                ))}
                {!loading && !user.id && (
                    <>
                        <Box m={4}>
                            <Button onClick={() => signupRef.current.openModal('Sign up', 'solid')} variant="solid" colorScheme="blue" w="100%">
                                Sign Up
                            </Button>
                        </Box>
                        <Box mx={4}>
                            <Button onClick={() => signupRef.current.openModal('Login', 'ghost')} variant="ghost" colorScheme="blue" w="100%">
                                Login
                            </Button>
                        </Box>
                    </>
                )}
            </Flex>
            <Flex flexDirection="column">
                {user.id && (
                    <NavItem
                        icon={FiSettings}
                        mb={"8px"}
                        selectChat={selectChat}
                        router={router}
                        isActive={router.pathname === '/settings'}
                        url='/settings'
                        user={user}
                    >
                        Settings
                    </NavItem>
                )}
                {displayMobileLinks && (
                    <VStack spacing={2} mb={4} mx={4} align="start">
                        <Link href="/privacy-policy" passHref>
                            <Text pl="15px" py="10px" cursor="pointer" _hover={{ textDecoration: 'underline' }}>Privacy Policy</Text>
                        </Link>
                        <Link href="/contact" passHref>
                            <Text pl="15px" py="10px" cursor="pointer" _hover={{ textDecoration: 'underline' }}>Contact</Text>
                        </Link>
                    </VStack>
                )}
            </Flex>
        </Box>
    );
}

const NavItem = ({ icon, children, isChats, chats, selectChat, setChats, isActive, url, router, resetSelectedChatId, handleChatReset, signupRef, loading, handleCreateNewChat, user, ...rest }) => {
    const childChats = chats && chats.length > 0 ? chats.slice(0, 7) : [];

    const isChatsActive = router.pathname === '/chats' && !router.query.chat;

    let activeStyle = isChatsActive ? {
        color: 'white',
        fontWeight: 'bold'
    } : {};

    const handleClick = (e) => {
        if (!user.id && isChats) {
            e.preventDefault();
            signupRef.current.openModal('Sign up', 'solid', 'to save your chats');
        } else {
            if (children === 'Create New Chat') {
                handleCreateNewChat();
            } else {
                router.push(url);
            }
        }
    };

    if (isChats && chats) {
        return (
            <>
                <Link href={url} passHref prefetch={false}>
                    <Flex
                        {...activeStyle}
                        align="center"
                        p="4"
                        mx="4"
                        borderRadius="lg"
                        role="group"
                        className='cursorPointer'
                        _hover={{
                            bg: 'gray.700',
                            color: 'white',
                        }}
                        onMouseEnter={() => {
                            if (url) {
                                window.fetch(url);
                            }
                        }}
                        onClick={handleClick}
                        {...rest}
                    >
                        {icon && (
                            <Icon
                                mr="4"
                                fontSize="16"
                                _groupHover={{
                                    color: 'white',
                                }}
                                as={icon}
                            />
                        )}
                        <Text>
                            Chats
                        </Text>
                    </Flex>
                </Link>
                <Reorder.Group axis="y" values={childChats} onReorder={setChats}>
                    {childChats.map((chat) => {
                        const isChildChatActive = router.query.chat === chat.id.toString();
                        const childChatStyle = isChildChatActive ? {
                            color: 'white',
                            fontWeight: 'bold',
                        } : {};

                        return (
                            <Reorder.Item key={chat.id} value={chat} id={chat.id.toString()}
                                transition={{ duration: 0.2 }}>
                                <Flex
                                    {...childChatStyle}
                                    borderLeft="1px"
                                    borderLeftColor={useColorModeValue('gray.200', 'gray.700')}
                                    align="center"
                                    py="2"
                                    mx="2"
                                    ml={'35px'}
                                    role="group"
                                    className='cursorPointer'
                                    fontSize={'0.9em'}
                                    _hover={{
                                        bg: 'gray.700',
                                        color: 'white',
                                        borderRadius: "0 0.5rem 0.5rem 0",
                                        _after: {
                                            content: '""',
                                            position: 'absolute',
                                            top: 0,
                                            right: 0,
                                            width: '20%',
                                            height: '100%',
                                            backgroundImage: 'linear-gradient(to left, rgba(45,55,72,1), rgba(45,55,72,0))',
                                            borderRadius: "0 0.5rem 0.5rem 0",
                                        }
                                    }}
                                    onClick={() => selectChat(chat.id)}
                                    position="relative"
                                >
                                    <Text ml={2}
                                        _after={{
                                            content: '""',
                                            position: 'absolute',
                                            top: 0,
                                            right: 0,
                                            width: '20%',
                                            height: '100%',
                                            backgroundImage: 'linear-gradient(to left, rgba(26,32,44,1) 1%, hsla(0,0%,100%,0));',
                                        }}
                                        _groupHover={{
                                            _after: {
                                                backgroundImage: 'linear-gradient(to left, #2d3748, hsla(0,0%,100%,0))',
                                            }
                                        }}
                                        position="relative"
                                        overflow='hidden'
                                        whiteSpace='nowrap'
                                        textOverflow='clip'
                                        w="-webkit-fill-available"
                                    >
                                        {chat.name}
                                    </Text>
                                </Flex>
                            </Reorder.Item>
                        );
                    })}
                </Reorder.Group>
            </>
        );
    } if (children === 'Create New Chat') {
        return (
            <Button
                as="div"
                onClick={handleClick}
                mx={4}
                my={4}
                style={{ textDecoration: 'none' }}
                _focus={{ boxShadow: 'none' }}
                cursor='pointer'
                {...rest}>
                <Flex
                    color={'gray.300'}
                    cursor='pointer'
                    align="center"
                    role="group"
                    {...rest}>
                    {icon && (
                        <Icon
                            mr="4"
                            fontSize="16"
                            _groupHover={{
                                color: 'white',
                            }}
                            as={icon}
                        />
                    )}
                    {children}
                </Flex>
            </Button>
        );
    }
    return (
        <Link href={url || '#'} passHref prefetch={false}>
            <Box
                as="a"
                style={{ textDecoration: 'none' }}
                _focus={{ boxShadow: 'none' }}
                cursor='pointer'
                onMouseEnter={() => {
                    if (url) {
                        window.fetch(url);
                    }
                }}
                onClick={handleClick}
                {...rest}>
                <Flex
                    color={'gray.300'}
                    cursor='pointer'
                    align="center"
                    p="4"
                    mx="4"
                    borderRadius="lg"
                    role="group"
                    _hover={{
                        bg: 'gray.700',
                        color: 'white',
                    }}
                    {...rest}>
                    {icon && (
                        <Icon
                            mr="4"
                            fontSize="16"
                            _groupHover={{
                                color: 'white',
                            }}
                            as={icon}
                        />
                    )}
                    {children}
                </Flex>
            </Box>
        </Link>
    );
};

const MobileNav = ({ onOpen, router, resetSelectedChatId, handleChatReset, handleCreateNewChat, ...rest }) => {
    return (
        <Flex
            ml={{ base: 0, md: 60 }}
            px={{ base: 4, md: 24 }}
            height="20"
            alignItems="center"
            bg={useColorModeValue('white', 'gray.900')}
            borderBottomWidth="1px"
            borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
            justifyContent="space-between"
            {...rest}
        >
            <IconButton
                variant="outline"
                onClick={onOpen}
                aria-label="open menu"
                icon={<FiMenu />}
            />
            <Box
                position="absolute"
                left="50%"
                transform="translateX(-50%)"
                onClick={() => {
                    router.push('/');
                    resetSelectedChatId();
                    handleChatReset();
                    handleCreateNewChat();
                }}
                cursor="pointer"
            >
                <Image
                    boxSize="120px"
                    height="auto"
                    objectFit="contain"
                    src="uprelic-logo-transparent.png"
                    alt="Uprelic"
                />
            </Box>
            <Box width="40px"></Box>
        </Flex>
    );
};
