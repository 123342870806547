import React from "react";
import { useRouter } from "next/router";
import { Flex, IconButton } from "@chakra-ui/react";
import { FiSearch, FiList } from "react-icons/fi";
import { BsCollection } from "react-icons/bs";

const BottomNavBar = ({ resetSelectedChatId, handleChatReset, editorRef }) => {
    const router = useRouter();

    const handleChatClick = () => {
        resetSelectedChatId();
        handleChatReset();
        router.push('/');
        if (editorRef.current) {
            editorRef.current.focus();
        }
    };

    return (
        <Flex
            pos="fixed"
            bottom="0"
            width="100%"
            bg="gray.800"
            color="white"
            justifyContent="space-around"
            py={2}
            display={{ base: "flex", md: "none" }}
        >
            <IconButton
                aria-label="Chat"
                fontSize="25px"
                icon={<FiSearch />}
                onClick={handleChatClick}
                variant="ghost"
                color={router.pathname === '/' ? 'white' : 'whiteAlpha.500'}
                _hover={{ backgroundColor: 'transparent' }}
            />
            <IconButton
                aria-label="Service Overview"
                fontSize="25px"
                icon={<BsCollection />}
                onClick={() => router.push('/service-overview')}
                variant="ghost"
                color={router.pathname === '/service-overview' ? 'white' : 'whiteAlpha.500'}
                _hover={{ backgroundColor: 'transparent' }}
            />
            <IconButton
                aria-label="Chats Overview"
                fontSize="25px"
                icon={<FiList />}
                onClick={() => router.push('/chats')}
                variant="ghost"
                color={router.pathname === '/chats' ? 'white' : 'whiteAlpha.500'}
                _hover={{ backgroundColor: 'transparent' }}
            />
        </Flex>
    );
};

export default BottomNavBar;
