module.exports = {
    FIGMA_INSTANCES_TABLE: "figma_instances",
    FIGMA_USERS_TABLE: "supabase_users_figma_instances",
    FIGMA_PROJECTS_TABLE: 'figma_projects',
    FIGMA_FILES_TABLE: "figma_files",
    FIGMA_COMMENTS_TABLE: "figma_comments",
    FIGMA_PROJECTS_SUPABASE_USERS: 'figma_projects_supabase_users',
    FIGMA_FILES_SUPABASE_USERS: 'figma_files_supabase_users',
    FIGMA_FILE_CONTENT_TABLE: 'figma_file_content',

    ATLASSIAN_INSTANCES_TABLE: "atlassian_instances",
    ATLASSIAN_USERS_TABLE: "supabase_users_atlassian_instances",
    JIRA_PROJECTS_TABLE: "jira_projects",
    JIRA_ISSUES_TABLE: "jira_issues",
    JIRA_COMMENTS_TABLE: "jira_comments",
    JIRA_ATTACHMENTS_TABLE: "jira_attachments",
    JIRA_PROJECTS_SUPABASE_USERS: "jira_projects_supabase_users",
    JIRA_ISSUES_SUPABASE_USERS: "jira_issues_supabase_users",
    JIRA_COMMENTS_SUPABASE_USERS: "jira_comments_supabase_users",
    JIRA_ATTACHMENTS_SUPABASE_USERS:"jira_attachments_supabase_users",

    SLACK_INSTANCES_TABLE: "slack_instances",
    SLACK_USERS_TABLE: "supabase_users_slack_instances",
    SLACK_CHANNELS_TABLE: "slack_channels",
    SLACK_MESSAGES_TABLE: "slack_messages",
    SLACK_CHANNELS_SUPABASE_USERS: "slack_channels_supabase_users",
    SLACK_MESSAGES_SUPABASE_USERS: "slack_messages_supabase_users",

    GMAIL_USERS_TABLE: "supabase_users_gmail_instances",
    GMAIL_INSTANCES_TABLE: "gmail_instances",
    GMAIL_MESSAGES_TABLE: "gmail_messages",

    NOTION_INSTANCES_TABLE: 'notion_instances',
    NOTION_USERS_TABLE: 'supabase_users_notion_instances',
    NOTION_DATABASES_TABLE: 'notion_databases',
    NOTION_BLOCKS_TABLE: 'notion_blocks',
    NOTION_PAGES_TABLE: 'notion_pages',
    NOTION_FILES_TABLE: 'notion_files',
    NOTION_COMMENTS_TABLE: 'notion_comments',
    NOTION_DATABASES_SUPABASE_USERS: 'notion_databases_supabase_users',
    NOTION_PAGES_SUPABASE_USERS: 'notion_pages_supabase_users',
    NOTION_BLOCKS_SUPABASE_USERS: 'notion_blocks_supabase_users',

    WEAVIATE_INDEX_STATUS: 'weaviate_indexing_status',

    CHATS: 'chats',

    GLOBAL_DATA: 'global_data',
  }