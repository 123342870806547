import React from 'react';
import { Box, Menu, MenuButton, MenuList, MenuItem, IconButton } from '@chakra-ui/react';
import { AiOutlineQuestion } from "react-icons/ai";

const FooterAsButton = () => {
    return (
        <Box position="fixed" bottom="20px" right="20px" zIndex="100">
            <Menu>
                <MenuButton
                    as={IconButton}
                    icon={<AiOutlineQuestion />}
                    variant="outline"
                    size="sm"
                    borderRadius="full"
                    bg='gray.400'
                    color='gray.900'
                    _hover={{
                        bg: 'white',
                    }}
                />
                <MenuList>
                    <MenuItem as="a" href="/privacy-policy">Privacy Policy</MenuItem>
                    <MenuItem as="a" href="/contact">Contact</MenuItem>
                </MenuList>
            </Menu>
        </Box>
    );
};

export default FooterAsButton;
