import React, { useEffect, useRef } from 'react';
import { useToast } from '@chakra-ui/react';
import { supabaseClient } from "./client";
import { WEAVIATE_INDEX_STATUS } from '../config';

const JobsToastNotifier = () => {
    const toast = useToast();
    const activeToastRef = useRef(null);
    const indexingIntervals = useRef({});

    const updateToast = (toastId, title, description, status = "info", duration = null) => {
        if (toast.isActive(toastId)) {
            toast.update(toastId, {
                title,
                description,
                status,
                position: 'top',
                isClosable: true,
                duration,
            });
        }
    };

    const fetchJobsInProgress = (payload) => {
        const service = payload.new.service;
        const totalDocuments = payload.new.count_found;
        let title = `Your ${capitalizeFirstLetter(service)} data`;
        let description;
        let status = "info";

        if (payload.eventType === 'INSERT') {
            description = "Searching for all data.";
            showToast(title, description, status);
        } else if (payload.eventType === 'UPDATE') {
            if (payload.new.indexing_status === 'progress') {
                if (!indexingIntervals.current[payload.new.id]) {
                    description = `Found ${totalDocuments} documents.`;
                    showToast(title, description, status);
                }
            }
            if (payload.new.indexing_status === 'complete') {
                indexingIntervals.current[payload.new.id] = { indexedDocuments: 0, intervalId: null };

                const intervalId = setInterval(() => {
                    const currentIndexed = indexingIntervals.current[payload.new.id].indexedDocuments += 1;
                    const currentPercentage = Math.round((currentIndexed / totalDocuments) * 100) || 0;
                    description = `${currentPercentage}% documents ready to use`;

                    updateToast(activeToastRef.current, title, description);

                    if (currentIndexed >= totalDocuments) {
                        clearInterval(intervalId);
                        description = `Your ${service} data is ready to use.`;
                        updateToast(activeToastRef.current, title, description, "success", 5000);
                    }

                }, Math.random() * (20 - 10) + 10);
                indexingIntervals.current[payload.new.id].intervalId = intervalId;
            }
        }
    };

    const showToast = (title, description, status = "info", duration = null) => {
        const currentToastId = activeToastRef.current;

        if (currentToastId && toast.isActive(currentToastId)) {
            updateToast(currentToastId, title, description, status, duration);
        } else {
            const toastId = toast({
                title,
                description,
                status,
                position: 'top',
                isClosable: true,
                duration,
            });
            activeToastRef.current = toastId;
        }
    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    useEffect(() => {
        const jobSubscription = supabaseClient
            .channel('indexJobUpdates')
            .on('postgres_changes', { event: 'UPDATE', schema: 'public', table: WEAVIATE_INDEX_STATUS }, payload => {
                fetchJobsInProgress(payload);
            })
            .on('postgres_changes', { event: 'INSERT', schema: 'public', table: WEAVIATE_INDEX_STATUS }, payload => {
                fetchJobsInProgress(payload);
            })
            .subscribe();

        return () => {
            jobSubscription.unsubscribe();
            Object.values(indexingIntervals.current).forEach(({ intervalId }) => clearInterval(intervalId));
        };
    }, []);

    return null;
};

export default JobsToastNotifier;
